import React, { lazy, useEffect, useMemo, useRef, useState } from "react";
import TopBar from "../components/topBar/TopBar";
import Home from "../components/home/Home";
import About from "../components/about/About";
import Services from "../components/services/Services";
import Ratings from "../components/ratings/Ratings";
import Carousel from "../components/carousel/CarouselComponent";
import Contact from "../components/contact/Contact";
import { Language } from "../types/types";
import LazyLoad from "../utils/LazyLoad";
import { translateString } from "../utils/languageTranslations";

// Icons
import AutoStoriesOutlinedIcon from "@mui/icons-material/AutoStoriesOutlined";
import CollectionsOutlinedIcon from "@mui/icons-material/CollectionsOutlined";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import ThumbUpAltOutlinedIcon from "@mui/icons-material/ThumbUpAltOutlined";
import ContactPageOutlinedIcon from "@mui/icons-material/ContactPageOutlined";
import useLanguageHandler from "../hooks/useLanguageHandler";

const GoogleMapsComponent = lazy(
  () => import("../components/googleMapsComponent/GoogleMapsComponent")
);

const HomePage = ({
  languageState,
  setLanguageState,
}: {
  languageState: Language;
  setLanguageState: (language: Language) => void;
}) => {
  const homeRef = useRef(null);
  const aboutRef = useRef(null);
  const carouselRef = useRef(null);
  const servicesRef = useRef(null);
  const reviewsRef = useRef(null);
  const contactRef = useRef(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  useLanguageHandler(languageState, setLanguageState);

  const sections = useMemo(
    () => [
      {
        id: "about",
        label: translateString("About"),
        refObj: aboutRef,
        icon: <PersonOutlinedIcon />,
      },
      {
        id: "carousel",
        label: translateString("Pictures"),
        refObj: carouselRef,
        icon: <CollectionsOutlinedIcon />,
      },
      {
        id: "reviews",
        label: translateString("Reviews"),
        refObj: reviewsRef,
        icon: <ThumbUpAltOutlinedIcon />,
      },
      {
        id: "services",
        label: translateString("Services"),
        refObj: servicesRef,
        icon: <AutoStoriesOutlinedIcon />,
      },
      {
        id: "contact",
        label: translateString("Contact"),
        refObj: contactRef,
        icon: <ContactPageOutlinedIcon />,
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [languageState]
  );

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 0.5, // Adjust this threshold as needed
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
        }
      });
    }, options);

    sections.forEach((section) => {
      const element = document.getElementById(section.id);
      if (element) {
        observer.observe(element);
      }
    });

    return () => {
      sections.forEach((section) => {
        const element = document.getElementById(section.id);
        if (element) {
          observer.unobserve(element);
        }
      });
    };
  }, [sections]);

  const scrollToContent = (ref) => {
    if (ref.current) {
      ref.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <>
      <TopBar
        scrollToContent={scrollToContent}
        languageState={languageState}
        setLanguageState={setLanguageState}
        isMenuOpen={isMenuOpen}
        setIsMenuOpen={setIsMenuOpen}
        breadcrumbs={sections} // Passing the sections directly
      />
      <Home homeRef={homeRef} isMenuOpen={isMenuOpen} />
      <About aboutRef={aboutRef} />
      <Carousel carouselRef={carouselRef} />
      <Ratings reviewsRef={reviewsRef} />
      <Services servicesRef={servicesRef} />
      <LazyLoad>
        <GoogleMapsComponent />
      </LazyLoad>
      {/* <Reviews reviewsRef={reviewsRef} /> */}
      <Contact contactRef={contactRef} />
    </>
  );
};

export default HomePage;
