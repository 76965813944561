import "./TopBar.css";
import { Link } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import MenuIcon from "@mui/icons-material/Menu";
import { useEffect, useRef } from "react";
import useMobile from "../../hooks/useMobile";
import LanguageDropdown from "../languageDropdown/LanguageDropdown";
import { translateString } from "../../utils/languageTranslations";
import { getLanguage } from "../../utils/utils";
import useScrollPosition from "../../hooks/useScrollPosition";
import EventAvailableIcon from "@mui/icons-material/EventAvailable";

const TopBar = ({
  scrollToContent,
  languageState,
  setLanguageState,
  isMenuOpen,
  setIsMenuOpen,
  breadcrumbs,
}) => {
  const isMobileView = useMobile();
  const language = getLanguage();
  const isScrolledDown = useScrollPosition();
  const modalRef = useRef(null);

  const handleClickOutside = (event) => {
    if (modalRef.current && !modalRef.current.contains(event.target)) {
      setIsMenuOpen(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  useEffect(() => {
    if (isMenuOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMenuOpen]);

  return (
    <header className={`topBar ${isScrolledDown ? "topBar-shadow" : ""}`}>
      <div
        className={`menu-button-wrapper ${
          isMenuOpen ? "menu-button-wrapper-open" : ""
        } ${isScrolledDown && !isMenuOpen ? "menu-button-wrapper-light" : ""}`}
      >
        {isMobileView && !isMenuOpen && (
          <div onClick={scrollToTop} className="topBar-logo no-select">
            NAILDAY
          </div>
        )}
        {isMobileView && (
          <LanguageDropdown
            isMobileView={isMobileView}
            languageState={languageState}
            setLanguageState={setLanguageState}
            isMenuOpen={isMenuOpen}
          />
        )}
        <div className="menu-button" onClick={() => setIsMenuOpen(!isMenuOpen)}>
          {isMenuOpen ? (
            <CloseIcon fontSize="large" />
          ) : (
            <MenuIcon fontSize="large" />
          )}
        </div>
      </div>
      <nav style={{}} className="topBar-nav">
        <ul
          ref={modalRef}
          className={`topBar-menu ${isMenuOpen ? "open" : ""} ${
            isScrolledDown ? "topBar-menu-light" : ""
          }`}
        >
          {breadcrumbs.map((item, index) => {
            if (Math.floor(breadcrumbs.length / 2) === index) {
              return (
                <>
                  <li
                    key={item.id}
                    className="topBar-item"
                    onClick={() => {
                      scrollToContent(item.refObj, item.id);
                      setIsMenuOpen(false);
                    }}
                  >
                    {item.icon}
                    {isMobileView && item.label}
                  </li>
                  {!isMobileView && (
                    <li
                      onClick={scrollToTop}
                      key="topbar-logo"
                      className="topBar-logo"
                    >
                      NAILDAY
                    </li>
                  )}
                </>
              );
            }

            return (
              <li
                key={item.id}
                className="topBar-item"
                onClick={() => {
                  scrollToContent(item.refObj, item.id);
                  setIsMenuOpen(false);
                }}
              >
                {item.icon}
                {isMobileView && item.label}
              </li>
            );
          })}

          <li
            key="topbar-lang"
            className={`${isMobileView ? "topBar-item" : "topBar-item-last"} `}
          >
            {isMobileView && <EventAvailableIcon />}
            {isMobileView && (
              <Link
                className={`${!isMobileView && "topBar-appointment"}  link ${
                  isScrolledDown && !isMobileView
                    ? "topBar-appointment-animation"
                    : ""
                }`}
                to={`/${language}/${translateString("booking-service-select")}`}
              >
                {translateString("Book Now")}
              </Link>
            )}
            {!isMobileView && (
              <LanguageDropdown
                isMobileView={isMobileView}
                languageState={languageState}
                setLanguageState={setLanguageState}
                isMenuOpen={isMenuOpen}
              />
            )}
          </li>
        </ul>
      </nav>
    </header>
  );
};

export default TopBar;
