import React from "react";
import "./DrawOutlineButton.css";

const DrawOutlineButton = ({
  children,
  ...rest
}: React.DetailedHTMLProps<
  React.ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
>) => {
  return (
    <button {...rest} className="draw-outline-button">
      <span>{children}</span>

      {/* TOP */}
      <span className="top-line" />

      {/* RIGHT */}
      <span className="right-line" />

      {/* BOTTOM */}
      <span className="bottom-line" />

      {/* LEFT */}
      <span className="left-line" />
    </button>
  );
};

export default DrawOutlineButton;
