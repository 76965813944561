import React from "react";
import "./Home.css";
import MarqueeComponent from "../marqueeComponent/MarqueeComponent";
import MainImage from "../../assets/main-image-bottom.jpg";
import { translateString } from "../../utils/languageTranslations";
import { Link } from "react-router-dom";
import { getLanguage } from "../../utils/utils";
import DrawOutlineButton from "../DrawOutlineButton/DrawOutlineButton";
import TouchAppIcon from "@mui/icons-material/TouchApp";
import useScrollPosition from "../../hooks/useScrollPosition";
import useScrolledToBottom from "../../hooks/useScrollToBottom";
import useMobile from "../../hooks/useMobile";

const Home = ({
  homeRef,
  isMenuOpen,
}: {
  homeRef?: React.RefObject<HTMLDivElement>;
  isMenuOpen: boolean;
}) => {
  const language = getLanguage();
  const isScrolledDown = useScrollPosition(window.innerHeight - 100);
  const isScrolledToBottom = useScrolledToBottom();
  const isMobileView = useMobile();

  return (
    <section id="home" ref={homeRef} className="home">
      <MarqueeComponent
        styleTop="2rem"
        elements={[
          translateString("Welcome to NAILDAY"),
          translateString(
            "Pick your service, choose your time, and you're set!"
          ),
          translateString(
            "We prioritize your health with strict sanitation standards."
          ),
          translateString(
            "Exclusive discounts for first-time clients and regulars!"
          ),
          translateString("Your Next Nail Appointment is Just a Click Away!"),
          translateString(
            "From classic manicures to intricate nail art—there's something for everyone."
          ),
        ]}
      />

      <div className="home-main">
        <div className="home-paragraph">
          <p>
            <span className="home-paragraph-logo">NAILDAY </span>
            {translateString(
              "is a space of trends, minimalism, bright colors, and self-love."
            )}
          </p>
          <br />
          <p>{translateString("Start your day with a perfect manicure.")}</p>
          <br />
          <p>
            {translateString(
              "I will help break stereotypes about nail extensions, gel coatings, nail plate strengthening, and nail design."
            )}
          </p>
          <br />
          <p>{translateString("Come and treat yourself today!")}</p>
        </div>

        <div className="home-image-container">
          <Link
            className="link home-main-button"
            to={`/${language}/${translateString("booking-service-select")}`}
          >
            <DrawOutlineButton>
              {translateString("Book Appointment")}
            </DrawOutlineButton>
          </Link>
          <img src={MainImage} alt="Home" className="home-image" />
        </div>
        {isMobileView &&
          isScrolledDown &&
          !isScrolledToBottom &&
          !isMenuOpen && (
            <Link
              className={`topBar-appointment-mobile link no-select`}
              to={`/${language}/${translateString("booking-service-select")}`}
            >
              <div className="topBar-appointment-mobile-wrapper">
                {translateString("Book Now")}
                <TouchAppIcon className="topBar-appointment-mobile-icon" />
              </div>
            </Link>
          )}
      </div>
    </section>
  );
};

export default Home;
