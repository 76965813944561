import { getLanguage, setLanguage } from "./utils";

const translations = {
  "Welcome to NAILDAY": {
    en: "Welcome to NAILDAY",
    et: "Tere tulemast NAILDAYsse",
    ru: "Добро пожаловать в NAILDAY",
    uk: "Ласкаво просимо до NAILDAY",
  },
  "Pick your service, choose your time, and you're set!": {
    en: "Pick your service, choose your time, and you're set!",
    et: "Valige teenus, valige aeg ja olete valmis!",
    ru: "Выберите услугу, выберите время — и всё готово!",
    uk: "Обирайте послугу, час, і все готово!",
  },
  "We prioritize your health with strict sanitation standards.": {
    en: "We prioritize your health with strict sanitation standards.",
    et: "Meie prioriteet on teie tervis range hügieenistandardiga.",
    ru: "Мы ставим ваше здоровье на первое место благодаря строгим стандартам санитарии.",
    uk: "Ми пріоритезуємо ваше здоров’я завдяки суворим стандартам санітарії.",
  },
  "Exclusive discounts for first-time clients and regulars!": {
    en: "Exclusive discounts for first-time clients and regulars!",
    et: "Eksklusiivsed soodustused uutele ja püsiklientidele!",
    ru: "Эксклюзивные скидки для новых и постоянных клиентов!",
    uk: "Ексклюзивні знижки для нових та постійних клієнтів!",
  },
  "Your Next Nail Appointment is Just a Click Away!": {
    en: "Your Next Nail Appointment is Just a Click Away!",
    et: "Teie järgmine maniküüri aeg on vaid ühe kliki kaugusel!",
    ru: "Ваш следующий визит на маникюр — всего в одном клике!",
    uk: "Ваш наступний запис на манікюр — лише за один клік!",
  },
  "From classic manicures to intricate nail art—there's something for everyone.":
    {
      en: "From classic manicures to intricate nail art—there's something for everyone.",
      et: "Alates klassikalistest maniküüridest kuni keeruka küünekunstini – midagi igale maitsele.",
      ru: "От классического маникюра до изысканного дизайна ногтей — у нас есть всё для каждого.",
      uk: "Від класичного манікюру до витонченого дизайну нігтів — у нас є все для кожного.",
    },

  "Come and treat yourself today!": {
    en: "Come and treat yourself today!",
    et: "Tulge ja hellitage ennast täna!",
    ru: "Приходите и балуйте себя сегодня!",
    uk: "Приходьте і потіште себе сьогодні!",
  },
  "is a space of trends, minimalism, bright colors, and self-love.": {
    en: "is a space of trends, minimalism, bright colors, and self-love.",
    et: "on trendide, minimalismi, erksate värvide ja enesearmastuse ruum.",
    ru: "— это пространство трендов, минимализма, ярких цветов и любви к себе.",
    uk: "— це простір трендів, мінімалізму, яскравих кольорів та любові до себе.",
  },
  "Start your day with a perfect manicure.": {
    en: "Start your day with a perfect manicure.",
    et: "Alusta oma päeva täiusliku maniküüriga.",
    ru: "Начните свой день с идеального маникюра.",
    uk: "Розпочніть свій день з ідеального манікюру.",
  },
  "I will help break stereotypes about nail extensions, gel coatings, nail plate strengthening, and nail design.":
    {
      en: "I will help break stereotypes about nail extensions, gel coatings, nail plate strengthening, and nail design.",
      et: "Ma aitan murda stereotüüpe küünte pikenduste, geelkattete, küüneplaadi tugevdamise ja küünte disaini kohta.",
      ru: "Я помогу разрушить стереотипы о наращивании ногтей, гелевых покрытиях, укреплении ногтевой пластины и дизайне ногтей.",
      uk: "Я допоможу зруйнувати стереотипи про нарощування нігтів, гелеві покриття, зміцнення нігтьової пластини та дизайн нігтів.",
    },
  "Book Appointment": {
    en: "Book Appointment",
    et: "Broneeri aeg",
    ru: "Записаться на прием",
    uk: "Записатися на прийом",
  },
  // TOP BAR
  Home: {
    en: "Home",
    et: "Avaleht",
    ru: "Главная",
    uk: "Головна",
  },
  About: {
    en: "About",
    et: "Meist",
    ru: "О нас",
    uk: "Про нас",
  },
  SERVICES: {
    en: "Services",
    et: "TEENUSED",
    ru: "УСЛУГИ",
    uk: "ПОСЛУГИ",
  },
  Reviews: {
    en: "Reviews",
    et: "Arvustused",
    ru: "Отзывы",
    uk: "Відгуки",
  },
  Pictures: {
    en: "Pictures",
    et: "Pildid",
    ru: "Изображения",
    uk: "Зображення",
  },
  Contact: {
    en: "Contact",
    et: "Kontakt",
    ru: "Контакт",
    uk: "Контакт",
  },
  "Book Now": {
    en: "Book Now",
    et: "Broneeri kohe",
    ru: "Записаться сейчас",
    uk: "Записатися зараз",
  },
  "Go To Homepage": {
    en: "Go To Homepage",
    et: "Mine avalehele",
    ru: "Перейти на главную страницу",
    uk: "Перейти на головну сторінку",
  },
  BACK: {
    en: "BACK",
    et: "TAGASI",
    ru: "НАЗАД",
    uk: "НАЗАД",
  },
  // ABOUT PAGE
  "ABOUT US": {
    en: "ABOUT US",
    et: "MEIST",
    ru: "О НАС",
    uk: "ПРО НАС",
  },
  "Hello! Let's get to know each other!": {
    en: "Hello! Let's get to know each other!",
    et: "Tere! Saame tuttavaks!",
    ru: "Здравствуйте! Давайте познакомимся!",
    uk: "Привіт! Давайте познайомимося!",
  },
  "I am Diana, a nail technician with more than 5 years of experience in the nail service industry.":
    {
      en: "I am Diana, a nail technician with more than 5 years of experience in the nail service industry.",
      et: "Mina olen Diana, küünetehnik, kellel on üle viie aasta kogemust küüneteeninduse valdkonnas.",
      ru: "Я Диана, мастер маникюра с более чем 5-летним опытом работы в индустрии ногтевого сервиса.",
      uk: "Я Діана, майстер манікюру з досвідом понад 5 років у сфері нігтьового сервісу.",
    },
  "I look forward to meeting you soon and making your life brighter, simpler, and more comfortable, so that all passersby can't take their eyes off you.":
    {
      en: "I look forward to meeting you soon and making your life brighter, simpler, and more comfortable, so that all passersby can't take their eyes off you.",
      et: "Ootan väga, et teiega peagi kohtuda ja muuta teie elu säravamaks, lihtsamaks ja mugavamaks, nii et kõik möödujad ei saa teilt silmi ära.",
      ru: "Я с нетерпением жду встречи с вами, чтобы сделать вашу жизнь ярче, проще и комфортнее, чтобы все прохожие не могли отвести от вас взгляд.",
      uk: "Я з нетерпінням чекаю зустрічі з вами, щоб зробити ваше життя яскравішим, простішим і комфортнішим, щоб усі перехожі не могли відвести від вас погляд.",
    },

  //Gallery section
  GALLERY: {
    en: "GALLERY",
    et: "GALERII",
    ru: "ГАЛЕРЕЯ",
    uk: "ГАЛЕРЕЯ",
  },
  // Rating section
  "SATISFIED CUSTOMERS": {
    en: "SATISFIED CUSTOMERS",
    et: "RAHULOLEVAD KLIENDID",
    ru: "ДОВОЛЬНЫЕ КЛИЕНТЫ",
    uk: "ЗАДОВОЛЕНІ КЛІЄНТИ",
  },
  "Happy customers": {
    en: "Happy customers",
    et: "Õnnelikud kliendid",
    ru: "Счастливые клиенты",
    uk: "Щасливі клієнти",
  },
  "Rate of returned customers": {
    en: "Rate of returned customers",
    et: "Tagasi tulnud klientide määr",
    ru: "Процент вернувшихся клиентов",
    uk: "Рівень повернення клієнтів",
  },
  "Happy nails": {
    en: "Happy nails",
    et: "Õnnelikud küüned",
    ru: "Счастливые ногти",
    uk: "Щасливі нігті",
  },
  "Customer reviews": {
    en: "Customer reviews",
    et: "Kliendi arvustused",
    ru: "Отзывы клиентов",
    uk: "Відгуки клієнтів",
  },
  // SERVICES PAGE
  "The classic type of manicure is always elegance and modesty. It suits any look and remains popular even today.":
    {
      en: "The classic type of manicure is always elegance and modesty. It suits any look and remains popular even today.",
      et: "Klassikaline maniküür on alati elegantne ja tagasihoidlik. See sobib iga välimusega ja on populaarne ka tänapäeval.",
      ru: "Классический маникюр всегда означает элегантность и скромность. Он подходит к любому образу и остаётся популярным даже сегодня.",
      uk: "Класичний тип манікюру завжди є елегантністю та скромністю. Він пасує до будь-якого образу і залишається популярним навіть сьогодні.",
    },
  CLASSIC: {
    en: "CLASSIC",
    et: "KLASSIKALINE",
    ru: "КЛАССИЧЕСКИЙ",
    uk: "КЛАСИЧНИЙ",
  },
  "Many people struggle with brittle, short, and misshapen nails, but nail extensions offer a solution. In just a few hours, you can enjoy beautiful, uniform, and long nails.":
    {
      en: "Many people struggle with brittle, short, and misshapen nails, but nail extensions offer a solution. In just a few hours, you can enjoy beautiful, uniform, and long nails.",
      et: "Paljud inimesed maadlevad haprate, lühikeste ja deformeerunud küüntega, kuid küünte pikendamine pakub lahendust. Vaid mõne tunni pärast saate nautida ilusaid, ühtlasi ja pikki küüsi.",
      ru: "Многие люди страдают от ломких, коротких и деформированных ногтей, но наращивание ногтей предлагает решение. Всего за несколько часов вы можете наслаждаться красивыми, ровными и длинными ногтями.",
      uk: "Багато людей страждають від ламких, коротких та деформованих нігтів, але нарощування нігтів пропонує вирішення. Всього за кілька годин ви можете насолоджуватися красивими, рівними та довгими нігтями.",
    },
  EXTENSIONS: {
    en: "EXTENSIONS",
    et: "PIKENDUSED",
    ru: "НАРАЩИВАНИЕ",
    uk: "НАРОЩУВАННЯ",
  },
  "The nail industry is always in sync with global trends, continuously evolving with new designs. If you're tired of basic flowers and swirls, come to me and let's create something truly innovative and inspiring.":
    {
      en: "The nail industry is always in sync with global trends, continuously evolving with new designs. If you're tired of basic flowers and swirls, come to me and let's create something truly innovative and inspiring.",
      et: "Küünte tööstus on alati kooskõlas globaalsete trendidega, pidevalt arenev uute disainidega. Kui olete väsinud tavalistest lilledest ja keeristest, tulge minu juurde ja loome midagi tõeliselt innovatiivset ja inspireerivat.",
      ru: "Ногтевая индустрия всегда идет в ногу с мировыми трендами, постоянно развиваясь с новыми дизайнами. Если вы устали от простых цветов и завитков, приходите ко мне, и давайте создадим что-то по-настоящему инновационное и вдохновляющее.",
      uk: "Нігтьова індустрія завжди йде в ногу з глобальними трендами, постійно розвиваючись з новими дизайнами. Якщо вам набридли прості квіти та завитки, приходьте до мене, і давайте створимо щось справді інноваційне та надихаюче.",
    },
  DESIGN: {
    en: "DESIGN",
    et: "DISAIN",
    ru: "ДИЗАЙН",
    uk: "ДИЗАЙН",
  },

  // LOCATION MAPS
  LOCATION: {
    en: "LOCATION",
    et: "ASUKOHT",
    ru: "МЕСТО НАХОЖДЕНИЯ",
    uk: "МІСЦЕ ЗНАХОДЖЕННЯ",
  },
  // FOOTER
  "Looking for a cozy spot to treat yourself? NailDay is the perfect place for fabulous nails! We've got everything you need, from basic manicures to fancy acrylic sets. Our talented nail technician will make you feel amazing, and she always adds a sprinkle of love. Meet Diana, our certified nail pro with over 5 years of experience. She's worked in lots of salons in Ukraine and now she's here to make your nails sparkle!":
    {
      en: "Looking for a cozy spot to treat yourself? NailDay is the perfect place for fabulous nails! We've got everything you need, from basic manicures to fancy acrylic sets. Our talented nail technician will make you feel amazing, and she always adds a sprinkle of love. Meet Diana, our certified nail pro with over 5 years of experience. She's worked in lots of salons in Ukraine and now she's here to make your nails sparkle!",
      et: "Otsid hubast kohta enese hellitamiseks? NailDay on suurepärane koht imeliste küünte jaoks! Meil on olemas kõik, mida vajate, alates lihtsatest maniküüridest kuni uhkete akrüülikomplektideni. Meie andekas küünetehnik teeb teid imeliselt tundma ja lisab alati armastusepiserduse. Tutvuge Dianaga, meie sertifitseeritud küüneprofiga, kellel on üle 5 aasta kogemusi. Ta on töötanud paljudes Ukraina salongides ja nüüd on ta siin, et teie küüned särama panna!",
      ru: "Ищете уютное место, чтобы побаловать себя? NailDay - идеальное место для потрясающих ногтей! У нас есть все, что вам нужно, от обычных маникюров до роскошных комплектов акрила. Наш талантливый мастер по ногтям заставит вас чувствовать себя удивительно, и она всегда добавляет щепотку любви. Познакомьтесь с Дианой, нашим сертифицированным мастером по ногтям с опытом работы более 5 лет. Она работала во многих салонах Украины, и теперь она здесь, чтобы ваши ногти сияли!",
      uk: "Шукаєте затишне місце, щоб порадувати себе? NailDay - ідеальне місце для чудових нігтів! У нас є все, що вам потрібно, від звичайних манікюрів до розкішних комплектів акрилу. Наш талановитий майстер по нігтях зробить вас чудовою, і вона завжди додає щипку любові. Познайомтеся з Діаною, нашим сертифікованим майстром по нігтях з більш ніж 5-річним досвідом роботи. Вона працювала в багатьох салонах України, і тепер вона тут, щоб ваші нігті сяяли!",
    },
  "Operating Hours": {
    en: "Operating Hours",
    et: "Tööaeg",
    ru: "Часы работы",
    uk: "Години роботи",
  },
  "Working hours": {
    en: "Working hours",
    et: "Tööajad",
    ru: "Рабочие часы",
    uk: "Робочі години",
  },
  "Need urgent manicure?": {
    en: "Need urgent manicure?",
    et: "Vajad kiiret maniküüri?",
    ru: "Нужен срочный маникюр?",
    uk: "Потрібен терміновий манікюр?",
  },
  "Upon agreement, reach out to me!": {
    en: "Upon agreement, reach out to me!",
    et: "Kokkuleppe korral võtke minuga ühendust!",
    ru: "По соглашению, свяжитесь со мной!",
    uk: "По угоді, зв'яжіться зі мною!",
  },
  "Questions? Call us here": {
    en: "Have questions? Call us here",
    et: "Küsimusi? Helista meile siin",
    ru: "Вопросы? Позвоните нам сюда",
    uk: "Є питання? Зателефонуйте нам сюди",
  },
  "In today's world, there's a lot of talk about embracing feminine energy and how to attain it. I have a special method to bring you closer to it. Come by, and I'll show you.":
    {
      en: "In today's world, there's a lot of talk about embracing feminine energy and how to attain it. I have a special method to bring you closer to it. Come by, and I'll show you.",
      et: "Tänapäeva maailmas räägitakse palju naiseliku energia omaksvõtmisest ja selle saavutamisest. Mul on eriline meetod, mis aitab sul sellele lähemale jõuda. Tule läbi, ja ma näitan sulle.",
      ru: "В современном мире много говорят о принятии женской энергии и о том, как её достичь. У меня есть особый метод, который поможет вам приблизиться к ней. Приходите, и я покажу вам.",
      uk: "У сучасному світі багато говорять про прийняття жіночої енергії та як її досягти. У мене є спеціальний метод, щоб наблизити вас до неї. Завітайте, і я покажу вам.",
    },
  // CAROUSEL
  "Completed Works": {
    en: "Completed Works",
    et: "Tehtud tööd",
    ru: "Завершенные работы",
    uk: "Виконані роботи",
  },
  // BOOKING SERVICES MODAL
  Manicure: {
    en: "Manicure",
    et: "Maniküür",
    ru: "Маникюр",
    uk: "Манікюр",
  },
  "Material Removal + Manicure": {
    en: "Material Removal + Manicure",
    et: "Materjali eemaldamine + maniküür",
    ru: "Удаление материала + маникюр",
    uk: "Зняття матеріалу + манікюр",
  },
  "Nail Extension": {
    en: "Nail Extension",
    et: "Küünte pikendamine",
    ru: "Наращивание ногтей",
    uk: "Нарощення нігтів",
  },
  "Combined Manicure": {
    en: "Combined Manicure",
    et: "Kombineeritud maniküür",
    ru: "Комбинированный маникюр",
    uk: "Комбінований манікюр",
  },
  "Hardware Manicure": {
    en: "Hardware Manicure",
    et: "Aparaatne maniküür",
    ru: "Аппаратный маникюр",
    uk: "Апаратний манікюр",
  },
  "Long Nail Correction": {
    en: "Long Nail Correction",
    et: "Pikkade küünte korrigeerimine",
    ru: "Коррекция длинных ногтей",
    uk: "Корекція довгих нігтів",
  },
  "Manicure focuses on nail care without applying base or gel polish": {
    en: "Manicure focuses on nail care without applying base or gel polish",
    et: "Maniküür keskendub küünte hooldusele ilma alus- või geelilakki kandmata",
    ru: "Маникюр сосредоточен на уходе за ногтями без нанесения базового или гель-лака",
    uk: "Манікюр зосереджений на догляді за нігтями без нанесення бази або гель-лаку",
  },
  "This manicure service offers a choice between combined, hardware, or classic techniques, all focused on caring for your nails without using base or gel polish. It’s all about keeping your nails healthy and looking great, while highlighting their natural beauty.":
    {
      en: "This manicure service offers a choice between combined, hardware, or classic techniques, all focused on caring for your nails without using base or gel polish. It’s all about keeping your nails healthy and looking great, while highlighting their natural beauty.",
      et: "See maniküüri teenus pakub valikut kombineeritud, aparaadi või klassikaliste tehnikate vahel, keskendudes küünte hooldamisele ilma alus- või geelilakki kasutamata. Kõik selleks, et hoida küüned terved ja ilusad, tuues esile nende loomuliku ilu.",
      ru: "Эта услуга маникюра предлагает выбор между комбинированными, аппаратными или классическими техниками, сосредоточенными на уходе за ногтями без использования базового или гель-лака. Все направлено на поддержание здоровья и красоты ногтей, подчеркивая их естественную красоту.",
      uk: "Ця послуга манікюру пропонує вибір між комбінованими, апаратними або класичними техніками, що зосереджуються на догляді за нігтями без використання бази або гель-лаку. Усе для того, щоб підтримувати здоров’я та красу нігтів, підкреслюючи їх природну красу.",
    },
  "Removal of old material, followed by a manicure without applying base or gel polish":
    {
      en: "Removal of old material, followed by a manicure without applying base or gel polish",
      et: "Vana materjali eemaldamine, millele järgneb maniküür ilma alus- või geelilakki kandmata",
      ru: "Снятие старого материала, за которым следует маникюр без нанесения базового или гель-лака",
      uk: "Зняття старого матеріалу, за яким слідує манікюр без нанесення бази або гель-лаку",
    },
  "This service starts by removing any old material from your nails, followed by a manicure using your choice of combined, hardware, or classic techniques. No new polish or coating is applied, focusing on maintaining the natural beauty of your nails.":
    {
      en: "This service starts by removing any old material from your nails, followed by a manicure using your choice of combined, hardware, or classic techniques. No new polish or coating is applied, focusing on maintaining the natural beauty of your nails",
      et: "See teenus algab vana materjali eemaldamisega küüntelt, millele järgneb maniküür teie valitud kombineeritud, aparaadi või klassikaliste tehnikate abil. Uut lakki ega katet ei kanta peale, keskendudes küünte loomuliku ilu säilitamisele.",
      ru: "Эта услуга начинается со снятия старого материала с ваших ногтей, после чего выполняется маникюр с использованием выбранной вами комбинированной, аппаратной или классической техники. Новое покрытие или лак не наносятся, акцент на поддержании естественной красоты ваших ногтей.",
      uk: "Ця послуга починається зі зняття старого матеріалу з ваших нігтів, після чого виконується манікюр, використовуючи вибрані вами комбіновані, апаратні або класичні техніки. Новий лак або покриття не наносяться, зосереджуючись на збереженні природної краси ваших нігтів.",
    },
  "Removal of old material, manicure with drill bits and scissors, gel strengthening, and design coating":
    {
      en: "Removal of old material, manicure with drill bits and scissors, gel strengthening, and design coating",
      et: "Vana materjali eemaldamine, maniküür freesi ja kääridega, geeli tugevdamine ja disainkate",
      ru: "Снятие старого материала, маникюр с использованием фрезы и ножниц, укрепление гелем и покрытие с дизайном",
      uk: "Зняття старого матеріалу, манікюр з використанням фрези та ножиць, зміцнення гелем і покриття з дизайном",
    },
  "This service starts by removing old material from your nails, followed by a detailed manicure using both nail drill bits and scissors. It includes gel strengthening, with the option to extend up to three nails. Finally, a colored coating is applied, with your choice of design or a classic French finish.":
    {
      en: "This service starts by removing old material from your nails, followed by a detailed manicure using both nail drill bits and scissors. It includes gel strengthening, with the option to extend up to three nails. Finally, a colored coating is applied, with your choice of design or a classic French finish",
      et: "See teenus algab vana materjali eemaldamisega küüntelt, millele järgneb põhjalik maniküür, kasutades nii küünetrimme kui ka kääre. See sisaldab geeli tugevdamist, võimalusega pikendada kuni kolme küünt. Lõpuks kantakse peale värviline kate, teie valikul kas disainiga või klassikalise prantsuse maniküüriga viimistlus.",
      ru: "Эта услуга начинается со снятия старого материала с ваших ногтей, после чего выполняется детализированный маникюр с использованием фрез и ножниц. Включено укрепление гелем с возможностью наращивания до трех ногтей. В завершение наносится цветное покрытие, по вашему выбору — дизайн или классический французский маникюр.",
      uk: "Ця послуга починається зі зняття старого матеріалу з ваших нігтів, після чого виконується детальний манікюр із використанням фрез і ножиць. Включає зміцнення гелем з можливістю наростити до трьох нігтів. Нарешті, наноситься кольорове покриття за вашим вибором: дизайн або класичний французький манікюр.",
    },
  "Removal of old material, manicure with drill bits, gel strengthening, and design coating":
    {
      en: "Removal of old material, manicure with drill bits, gel strengthening, and design coating",
      et: "Vana materjali eemaldamine, maniküür freesi abil, geeli tugevdamine ja disainkate",
      ru: "Снятие старого материала, маникюр с использованием фрезы, укрепление гелем и покрытие с дизайном",
      uk: "Зняття старого матеріалу, манікюр з використанням фрези, зміцнення гелем і покриття з дизайном",
    },
  "This service starts by removing old material from your nails, followed by a detailed manicure using nail drill bits. It includes gel strengthening, with the option to extend up to three nails. Finally, a colored coating is applied, with your choice of design or a classic French finish.":
    {
      en: "This service starts by removing old material from your nails, followed by a detailed manicure using nail drill bits. It includes gel strengthening, with the option to extend up to three nails. Finally, a colored coating is applied, with your choice of design or a classic French finish",
      et: "See teenus algab vana materjali eemaldamisega küüntelt, millele järgneb põhjalik maniküür, kasutades küünetrimme. See sisaldab geeli tugevdamist, võimalusega pikendada kuni kolme küünt. Lõpuks kantakse peale värviline kate, teie valikul kas disainiga või klassikalise prantsuse maniküüriga viimistlus.",
      ru: "Эта услуга начинается со снятия старого материала с ваших ногтей, после чего выполняется детализированный маникюр с использованием фрез. Включено укрепление гелем с возможностью наращивания до трех ногтей. В завершение наносится цветное покрытие, по вашему выбору — дизайн или классический французский маникюр.",
      uk: "Ця послуга починається зі зняття старого матеріалу з ваших нігтів, після чого виконується детальний манікюр із використанням фрез. Включає зміцнення гелем з можливістю наростити до трьох нігтів. Нарешті, наноситься кольорове покриття за вашим вибором: дизайн або класичний французький манікюр.",
    },
  "Professional nail extension, full nail plate preparation, and a colored coating":
    {
      en: "Professional nail extension, full nail plate preparation, and a colored coating",
      et: "Professionaalne küünte pikendamine, küüneplaadi täielik ettevalmistus ja värviline kate",
      ru: "Профессиональное наращивание ногтей, полная подготовка ногтевой пластины и цветное покрытие",
      uk: "Професійне нарощення нігтів, повна підготовка нігтьової пластини та кольорове покриття",
    },
  "This service provides professional nail extensions using either lower or upper forms, customized to your preferences and needs. It includes thorough preparation of the entire nail plate to ensure a smooth and lasting result. The service is completed with a colored coating and your choice of design or a classic French finish":
    {
      en: "This service provides professional nail extensions using either lower or upper forms, customized to your preferences and needs. It includes thorough preparation of the entire nail plate to ensure a smooth and lasting result. The service is completed with a colored coating and your choice of design or a classic French finish",
      et: "See teenus pakub professionaalset küünte pikendamist, kasutades kas alumisi või ülemisi vorme, kohandatud vastavalt teie eelistustele ja vajadustele. See sisaldab kogu küüneplaadi põhjalikku ettevalmistamist, et tagada sujuv ja kauakestev tulemus. Teenus lõpeb värvilise kattega ja teie valikul kas disaini või klassikalise prantsuse maniküüriga viimistlus.",
      ru: "Эта услуга предоставляет профессиональное наращивание ногтей с использованием нижних или верхних форм, адаптированных к вашим предпочтениям и потребностям. Включена тщательная подготовка всей ногтевой пластины для обеспечения гладкого и долговечного результата. Услуга завершается нанесением цветного покрытия и вашим выбором дизайна или классического французского маникюра.",
      uk: "Ця послуга надає професійне нарощування нігтів з використанням нижніх або верхніх форм, налаштованих відповідно до ваших уподобань та потреб. Включає ретельну підготовку всієї нігтьової пластини для забезпечення гладкого та довговічного результату. Послуга завершується нанесенням кольорового покриття та вашим вибором дизайну або класичного французького манікюру.",
    },
  "Removal of old material, a care manicure, shape adjustment, and a colored coating":
    {
      en: "Removal of old material, a care manicure, shape adjustment, and a colored coating",
      et: "Vana materjali eemaldamine, hooldav maniküür, kuju korrigeerimine ja värviline kate",
      ru: "Снятие старого материала, уходовый маникюр, коррекция формы и цветное покрытие",
      uk: "Зняття старого матеріалу, доглядовий манікюр, корекція форми та кольорове покриття",
    },
  "This service starts with the removal of old material from your nails, ensuring a clean base. A personalized manicure tailored to your nail condition follows, promoting health and appearance. Your nails are then shaped to your preference, and the service finishes with a colored coating of your choice, whether a unique design or classic French manicure":
    {
      en: "This service starts with the removal of old material from your nails, ensuring a clean base. A personalized manicure tailored to your nail condition follows, promoting health and appearance. Your nails are then shaped to your preference, and the service finishes with a colored coating of your choice, whether a unique design or classic French manicure",
      et: "See teenus algab vana materjali eemaldamisega küüntelt, tagades puhta aluse. Seejärel tehakse isikupärastatud maniküür, mis on kohandatud teie küünte seisundile, et edendada nende tervist ja välimust. Küüned vormitakse vastavalt teie eelistusele ja teenus lõpeb värvilise kattega, teie valikul kas unikaalse disainiga või klassikalise prantsuse maniküüriga.",
      ru: "Эта услуга начинается со снятия старого материала с ваших ногтей, обеспечивая чистую основу. Затем проводится персонализированный маникюр, адаптированный к состоянию ваших ногтей, способствующий их здоровью и внешнему виду. Ногти формируются в соответствии с вашими предпочтениями, и услуга завершается нанесением цветного покрытия по вашему выбору — уникального дизайна или классического французского маникюра.",
      uk: "Ця послуга починається зі зняття старого матеріалу з ваших нігтів, забезпечуючи чисту основу. Потім виконується індивідуальний манікюр, адаптований до стану ваших нігтів, що сприяє їх здоров’ю та зовнішньому вигляду. Нігті надаються форми відповідно до ваших уподобань, а послуга завершується нанесенням кольорового покриття на ваш вибір — унікального дизайну або класичного французького манікюру.",
    },
  Select: {
    en: "Select",
    et: "Vali",
    ru: "Выбрать",
    uk: "Вибрати",
  },
  // CALENDAR MODAL
  "Selected service": {
    en: "Selected service",
    et: "Valitud teenus",
    ru: "Выбранная услуга",
    uk: "Обрана послуга",
  },
  Duration: {
    en: "Duration",
    et: "Kestus",
    ru: "Длительность",
    uk: "Тривалість",
  },
  Date: {
    en: "Date",
    et: "Kuupäev",
    ru: "Дата",
    uk: "Дата",
  },
  "Available spots for: ": {
    en: "Available spots for: ",
    et: "Saadaolevad kohad kuupäevale: ",
    ru: "Доступные места на: ",
    uk: "Доступні місця на: ",
  },
  "Searching available dates": {
    en: "Searching available dates",
    et: "Saadaolevate kuupäevade otsimine",
    ru: "Поиск доступных дат",
    uk: "Пошук доступних дат",
  },
  // CONTACT FORM
  Name: {
    en: "Name",
    et: "Nimi",
    ru: "Имя",
    uk: "Ім'я",
  },
  Email: {
    en: "Email",
    et: "E-post",
    ru: "Электронная почта",
    uk: "Електронна пошта",
  },
  Phone: {
    en: "Phone",
    et: "Telefon",
    ru: "Телефон",
    uk: "Телефон",
  },
  "Comments/Remarks (optional)": {
    en: "Comments/Remarks (optional)",
    et: "Kommentaarid/Märkused (valikuline)",
    ru: "Комментарии/Примечания (необязательно)",
    uk: "Коментарі/Примітки (необов'язково)",
  },
  BOOK: {
    en: "BOOK",
    et: "BRONEERI",
    ru: "БРОНИРОВАТЬ",
    uk: "ЗАБРОНЮВАТИ",
  },
  // SUCCESS CONFIRM
  "Booking Confirmed": {
    en: "Booking Confirmed",
    et: "Broneering kinnitatud",
    ru: "Бронирование подтверждено",
    uk: "Бронювання підтверджено",
  },
  "Thank you": {
    en: "Thank you",
    et: "Aitäh",
    ru: "Спасибо",
    uk: "Дякую",
  },
  "for booking with us.": {
    en: "for booking with us.",
    et: "broneerimise eest meiega.",
    ru: "за бронирование с нами.",
    uk: "за бронювання з нами.",
  },
  "We look forward to seeing you for your": {
    en: "We look forward to seeing you for your",
    et: "Ootame teid oma",
    ru: "Мы с нетерпением ждем вас на ваш",
    uk: "Ми з нетерпінням чекаємо вас на ваш",
  },
  at: {
    en: "at",
    et: "kell",
    ru: "в",
    uk: "о",
  },
  on: {
    en: "on",
    et: "kuupäeval",
    ru: "на",
    uk: "на",
  },
  "We've sent you an email at": {
    en: "We've sent you an email at",
    et: "Me saatsime teile e-kirja aadressile",
    ru: "Мы отправили вам электронное письмо на",
    uk: "Ми відправили вам електронний лист на",
  },
  "with the booking confirmation": {
    en: "with the booking confirmation",
    et: "broneeringu kinnitusega",
    ru: "с подтверждением бронирования",
    uk: "з підтвердженням бронювання",
  },
  "Click here to add to your calendar": {
    en: "Click here to add to your calendar",
    et: "Klõpsa siia, et lisada oma kalendrisse",
    ru: "Нажмите сюда, чтобы добавить в свой календарь",
    uk: "Натисніть тут, щоб додати до свого календаря",
  },
  "Back to Home": {
    en: "Back to Home",
    et: "Tagasi avalehele",
    ru: "Вернуться на главную",
    uk: "Повернутися на головну",
  },

  //SUCCESS
  Service: {
    en: "Service",
    et: "Teenus",
    ru: "Услуга",
    uk: "Послуга",
  },
  Time: {
    en: "Time",
    et: "Aeg",
    ru: "Время",
    uk: "Час",
  },
  Details: {
    en: "Details",
    et: "Detailid",
    ru: "Детали",
    uk: "Деталі",
  },
  Success: {
    en: "Success",
    et: "Õnnestumine",
    ru: "Успех",
    uk: "Успіх",
  },

  //PROGRESS
  "Choose a service": {
    en: "Choose a service",
    et: "Vali teenus",
    ru: "Выберите услугу",
    uk: "Оберіть послугу",
  },
  "Pick a time": {
    en: "Pick a time",
    et: "Vali aeg",
    ru: "Выберите время",
    uk: "Виберіть час",
  },
  "Enter your details": {
    en: "Enter your details",
    et: "Sisestage oma andmed",
    ru: "Введите ваши данные",
    uk: "Введіть свої дані",
  },
  "Booking successful": {
    en: "Booking successful",
    et: "Broneering edukas",
    ru: "Бронирование успешно",
    uk: "Бронювання успішно",
  },
  //EROR PAGE
  "Oops! You shouldn't be here.": {
    en: "Oops! You shouldn't be here.",
    et: "Ups! Te ei peaks siin olema.",
    ru: "Упс! Вас здесь не должно быть.",
    uk: "Ой! Ви не повинні бути тут.",
  },
  "You will be redirected back to homepage in": {
    en: "You will be redirected back to homepage in",
    et: "Teid suunatakse tagasi avalehele",
    ru: "Вы будете перенаправлены обратно на главную страницу через",
    uk: "Вас буде перенаправлено назад на домашню сторінку через",
  },
  seconds: {
    en: "seconds",
    et: "sekundit",
    ru: "секунд",
    uk: "секунд",
  },
  "Something went wrong.": {
    en: "Something went wrong.",
    et: "Midagi läks valesti.",
    ru: "Что-то пошло не так.",
    uk: "Щось пішло не так.",
  },
  "Don't worry, our team is on it!": {
    en: "Don't worry, our team is on it!",
    et: "Ära muretse, meie meeskond tegeleb sellega!",
    ru: "Не волнуйтесь, наша команда разбирается в этом!",
    uk: "Не хвилюйтеся, наша команда працює над цим!",
  },
  "Go Back": {
    en: "Go Back",
    et: "Mine tagasi",
    ru: "Вернуться",
    uk: "Повернутися",
  },

  //UTILITY
  "hours-short-singular": {
    en: "h",
    et: "t",
    ru: "ч",
    uk: "г",
  },
  "hours-long-singular": {
    en: "hour",
    et: "tund",
    ru: "час",
    uk: "година",
  },
  "hours-short-plural": {
    en: "hrs",
    et: "t",
    ru: "ч",
    uk: "г",
  },
  "hours-long-plural": {
    en: "hours",
    et: "tundi",
    ru: "часов",
    uk: "годин",
  },
  "minutes-short": {
    en: "mins",
    et: "min",
    ru: "мин",
    uk: "хв",
  },
  "minutes-long": {
    en: "minutes",
    et: "minutit",
    ru: "минуты",
    uk: "хвилини",
  },

  // SEO
  "NailDay Nail Salon in Tallinn": {
    en: "NailDay Nail Salon in Tallinn",
    et: "NailDay küünesalong Tallinnas",
    ru: "Салон маникюра NailDay в Таллинне",
    uk: "Салон манікюру NailDay в Таллінні",
  },
  "Top Nail Salon in Tallinn | Best Manicure & Nail Art": {
    en: "Top Nail Salon in Tallinn | Best Manicure & Nail Art",
    et: "Parim küünesalong Tallinnas | Parimad maniküüri ja küünekunsti",
    ru: "Лучший маникюрный салон в Таллинне | Лучшие услуги маникюра и нейл-арта",
    uk: "Найкращий салон манікюру в Таллінні | Найкращі послуги манікюру та мистецтва нігтів",
  },
  "Top Nail Salon in Tallinn | Best Manicure & Nail Art Services": {
    en: "Top Nail Salon in Tallinn | Best Manicure & Nail Art Services",
    et: "Parim küünesalong Tallinnas | Parimad maniküüri ja küünekunsti teenused",
    ru: "Лучший маникюрный салон в Таллинне | Лучшие услуги маникюра и нейл-арта",
    uk: "Найкращий салон манікюру в Таллінні | Найкращі послуги манікюру та мистецтва нігтів",
  },
  "Discover the top nail salon in Tallinn offering best manicure, extension, and nail art services. Enjoy expert gel nails, acrylic nails, and custom designs. Book your appointment today for a best nail care experience!":
    {
      en: "Discover the top nail salon in Tallinn offering the best manicure, extension, and nail art services. Enjoy expert gel nails, acrylic nails, and custom designs. Book your appointment today for the best nail care experience!",
      et: "Avasta parim küünesalong Tallinnas, mis pakub parimat maniküüri, pikendust ja küünekunsti teenuseid. Naudi ekspertide poolt tehtud geelküüsi, akrüülküüsi ja kohandatud disaine. Broneeri oma aeg täna parima küünehoolduse kogemuse jaoks!",
      ru: "Откройте для себя лучший маникюрный салон в Таллинне, предлагающий лучшие услуги маникюра, наращивания и нейл-арта. Наслаждайтесь профессиональными гель-лаковыми и акриловыми ногтями и индивидуальными дизайнами. Запишитесь на прием сегодня для лучшего опыта по уходу за ногтями!",
      uk: "Дізнайтеся про найкращий салон манікюру в Таллінні, який пропонує найкращі послуги манікюру, нарощування та мистецтва нігтів. Насолоджуйтесь професійними гель-лаковими і акриловими нігтями та індивідуальними дизайнами. Запишіться на прийом сьогодні для найкращого досвіду догляду за нігтями!",
    },
  "nail salon Tallinn, manicure Tallinn, nailDay Tallinn, gel nails Tallinn, acrylic nails Tallinn, nail art Tallinn, best nail care Tallinn, best nail salon Tallinn, custom nail designs Tallinn, luxury nail salon Tallinn":
    {
      en: "nail salon Tallinn, manicure Tallinn, nailDay Tallinn, gel nails Tallinn, acrylic nails Tallinn, nail art Tallinn, best nail care Tallinn, best nail salon Tallinn, custom nail designs Tallinn, luxury nail salon Tallinn",
      et: "küünesalong Tallinn, maniküür Tallinn, NailDay Tallinn, geelküüned Tallinn, akrüülküüned Tallinn, küünemaalingud Tallinn, parim küünehooldus Tallinn, parim küünesalong Tallinn, kohandatud küünedisainid Tallinn, luksuslik küünesalong Tallinn",
      ru: "маникюрный салон Таллинн, маникюр Таллинн, салон маникюра NailDay в Таллинне, гель-лак ногтей Таллинн, акриловые ногти Таллинн, маникюрный дизайн Таллинн, лучший уход за ногтями Таллинн, лучший маникюрный салон Таллинн, индивидуальный дизайн ногтей Таллинн, роскошный маникюрный салон Таллинн",
      uk: "салон манікюру Таллінн, манікюр Таллінн, салон манікюру NailDay в Таллінні, гель-лак нігтів Таллінн, акрилові нігті Таллінн, дизайн нігтів Таллінн, кращий догляд за нігтями Таллінн, кращий салон манікюру Таллінн, індивідуальний дизайн нігтів Таллінн, розкішний салон манікюру Таллінн",
    },
  "Experience the best nail care services in Tallinn. Our salon offers the best manicure, nailDay, gel nails, acrylic nails, and custom nail art. Book now for a top-notch nail salon experience!":
    {
      en: "Experience the best nail care services in Tallinn. Our salon offers the best manicure, nailDay, gel nails, acrylic nails, and custom nail art. Book now for a top-notch nail salon experience!",
      et: "Koge Tallinnas parimaid küünehooldusteenuseid. Meie salong pakub parimat maniküüri, NailDay'd, geelküüsi, akrüülküüsi ja kohandatud küünekunsti. Broneeri kohe, et saada tipptasemel küünesalongi kogemus!",
      ru: "Почувствуйте лучший уход за ногтями в Таллинне. Наш салон предлагает лучший маникюр, NailDay, гель-лаки, акриловые ногти и индивидуальный нейл-арт. Забронируйте сейчас для первоклассного опыта в салоне красоты!",
      uk: "Відчуйте найкращий догляд за нігтями в Таллінні. Наш салон пропонує найкращий манікюр, NailDay, гель-лаки, акрилові нігті та індивідуальний мистецтво нігтів. Забронюйте зараз для першокласного досвіду в салоні краси!",
    },
  "Where to find us?": {
    en: "Where to find us?",
    et: "Kus meid leida?",
    ru: "Где нас найти?",
    uk: "Де нас знайти?",
  },
  "Get Directions": {
    en: "Get Directions",
    et: "Leia teejuhised",
    ru: "Проложить маршрут",
    uk: "Отримати напрямок",
  },
  //ROUTES
  "booking-service-select": {
    en: "booking-service-select",
    et: "broneeri-teenuse-valik",
    ru: "vybor-bronirovaniya-uslugi",
    uk: "vybir-poslugy-bronyuvannya",
  },
  "booking-date-select": {
    en: "booking-date-select",
    et: "broneeri-kuupäeva-valik",
    ru: "vybor-datyi-bronirovaniya",
    uk: "vybir-daty-bronyuvannya",
  },
  "booking-details": {
    en: "booking-details",
    et: "broneeri-andmed",
    ru: "dannyie-bronirovaniya",
    uk: "dani bronyuvannya",
  },
  "booking-confirmation": {
    en: "booking-confirmation",
    et: "broneeringu-kinnitus",
    ru: "podtverzhdenie-bronirovaniya",
    uk: "pidtverdzhennia-bronyuvannya",
  },
  "booking-failure": {
    en: "booking-failure",
    et: "broneeringu-ebaonnestumine",
    ru: "bronirovaniya-neudachno",
    uk: "bronyuvannya-nevdalo",
  },
};

export function translateString(phrase: any): any {
  const language = getLanguage();

  if (!language) {
    setLanguage("et");
  }

  if (translations[phrase] && translations[phrase][language]) {
    return translations[phrase][language];
  } else {
    return phrase;
  }
}
