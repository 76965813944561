import "./MarqueeComponent.css";
// animation: scroll 10s linear infinite; /* Smooth horizontal scroll */

const MarqueeComponent = ({
  elements,
  styleTop,
}: {
  elements: string[];
  styleTop: string;
}) => {
  return (
    <div
      style={{
        position: "relative",
        overflow: "hidden",
        top: styleTop,
      }}
      className="marquee-container"
    >
      <div
        style={{
          animation: `scroll ${elements.length * 10}s linear infinite`,
        }}
        className="marquee-content"
      >
        {elements.map((element, index) => (
          <>
            {index === 0 && <span>-&nbsp;&nbsp;&nbsp;</span>}
            <span key={index}>{element}</span>
            {index < elements.length - 1 && ( // Add spaces between elements
              <span>&nbsp;&nbsp;&nbsp;-&nbsp;&nbsp;&nbsp;</span>
            )}
            {index === elements.length - 1 && <span>&nbsp;&nbsp;&nbsp;-</span>}
          </>
        ))}
      </div>
    </div>
  );
};

export default MarqueeComponent;
