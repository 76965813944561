import "./TopBarMinified.css";
import UndoIcon from "@mui/icons-material/Undo";

import { Link } from "react-router-dom";
import { translateString } from "../../utils/languageTranslations";

const TopBarMinified = () => {
  return (
    <div className="topBarMinified">
      <Link className="link" to="/">
        <div className="topBarMinified-logo">NAILDAY</div>
      </Link>
      <ul>
        <Link className="link" to="/">
          <li key={"home"} className="topBarMinified-item">
            {translateString("BACK")}
            <UndoIcon />
          </li>
        </Link>
      </ul>
    </div>
  );
};

export default TopBarMinified;
