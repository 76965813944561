import "./Contact.css";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import PhoneIcon from "@mui/icons-material/Phone";
import MailIcon from "@mui/icons-material/Mail";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import { translateString } from "../../utils/languageTranslations";
import smileContact from "../../assets/smileContact.png";
import { getLanguage } from "../../utils/utils";
import { Link, useLocation } from "react-router-dom";
import AnimatedDiv from "../../utils/AnimatedDiv";

const Contact = ({
  contactRef,
}: {
  contactRef?: React.RefObject<HTMLDivElement>;
}) => {
  const language = getLanguage();

  const location = useLocation();

  const isMainPage =
    location.pathname === `/${language === "et" ? "" : language}`;

  return (
    <footer id="contact" ref={contactRef} className="contact">
      <AnimatedDiv className="contact-wrapper" isAnimationDisabled={true}>
        <div className="contact-top">
          <div className="contact-left">
            <h3>NAILDAY</h3>
            <p>
              {translateString(
                "In today's world, there's a lot of talk about embracing feminine energy and how to attain it. I have a special method to bring you closer to it. Come by, and I'll show you."
              )}
            </p>
            <img src={smileContact} alt="" />
          </div>
          <div className="contact-right">
            <div className="contact-right-1">
              <h3>{translateString("Operating Hours")}</h3>
              <h4>{translateString("Working hours")}</h4>
              <span>10:00 - 20:00</span>
              <h4>{translateString("Need urgent manicure?")}</h4>
              <span>{translateString("Upon agreement, reach out to me!")}</span>
              <h4>{translateString("Questions? Call us here")}:</h4>
              <span>+372 58 33 12 28</span>
            </div>
            <div className="contact-right-2">
              <h3>{translateString("Contact")}</h3>
              <div className="contact-right-2-location">
                <div className="contact-right-2-location-wrapper">
                  <LocationOnIcon className="contact-icon" />
                  <span>Kakumäe, Haabersti</span>
                </div>
                <span>Tallinn, Estonia</span>
              </div>
              <div className="contact-right-2-mail">
                <MailIcon fontSize="small" className="contact-icon" />
                <span>nailday.ee@gmail.ee</span>
              </div>
              <div
                className="contact-right-2-whatsapp"
                onClick={() => {
                  window.location.href = "https://wa.me/+37258331228";
                }}
              >
                <WhatsAppIcon className="contact-icon" />
                <span>Whatsapp</span>
              </div>
            </div>
          </div>
        </div>
        <div className="contact-bottom">
          <div className="contact-left-icons">
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.instagram.com/nailday.ee/"
              className="link"
              aria-label="Instagram link"
            >
              <InstagramIcon fontSize="large" className="contact-icon" />
            </a>

            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.facebook.com/profile.php?id=100041694949924"
              className="link"
              aria-label="Facebook link"
            >
              <FacebookIcon fontSize="large" className="contact-icon" />
            </a>

            <MailIcon
              onClick={() => {
                window.location.href = "mailto:info@nailday.ee";
              }}
              style={{ cursor: "pointer" }}
              fontSize="large"
              className="contact-icon"
            />
            <PhoneIcon
              onClick={() => {
                window.location.href = "tel:$+37258331228";
              }}
              fontSize="large"
              className="contact-icon"
              style={{ cursor: "pointer" }}
            />
          </div>
          {isMainPage ? (
            <Link
              className="link contact-button"
              to={`/${language}/${translateString("booking-service-select")}`}
            >
              {translateString("Book Appointment")}
            </Link>
          ) : (
            <Link className="link contact-button" to={`/${language}`}>
              {translateString("Go To Homepage")}
            </Link>
          )}
        </div>
      </AnimatedDiv>
    </footer>
  );
};

export default Contact;
