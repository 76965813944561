import React, { useEffect, useRef } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import "./Services.css";
import { translateString } from "../../utils/languageTranslations";
import serviceImg1 from "../../assets/serviceimg1.jpg";
import serviceImg2 from "../../assets/serviceimg2.jpg";
import serviceImg3 from "../../assets/serviceimg3.jpg";
import useMobile from "../../hooks/useMobile";
import AnimatedDiv from "../../utils/AnimatedDiv";
import AnimatedImage from "../../utils/AnimatedImage/AnimatedImage";

if (typeof window !== "undefined") {
  gsap.registerPlugin(ScrollTrigger);
}

const Services = ({
  servicesRef,
}: {
  servicesRef: React.RefObject<HTMLDivElement>;
}) => {
  const containerRef = useRef(null);
  const panelRefs = useRef<HTMLDivElement[]>([]);
  const scrollTriggerRef = useRef<ScrollTrigger | null>(null);
  const isMobile = useMobile();

  useEffect(() => {
    const panels = panelRefs.current;

    const createScrollTrigger = () => {
      scrollTriggerRef.current = ScrollTrigger.create({
        trigger: containerRef.current,
        pin: true,
        scrub: true,
        end: () => `+=${containerRef.current.offsetWidth}`,
        animation: gsap.to(panels, {
          xPercent: -100 * (panels.length - 1),
          ease: "none",
        }),
      });
    };

    !isMobile && createScrollTrigger();

    return () => {
      scrollTriggerRef.current?.kill();
    };
  }, [isMobile]);

  return (
    <section className="services" id="services" ref={servicesRef}>
      <div
        className={`outer-container ${
          isMobile ? "outer-container-mobile" : ""
        }`}
      >
        <AnimatedDiv className="services-top">
          <h1>{translateString("SERVICES")}</h1>
        </AnimatedDiv>
        <div
          className={`container ${isMobile ? "container-mobile" : ""}`}
          ref={containerRef}
        >
          <div className="panel" ref={(el) => (panelRefs.current[1] = el)}>
            <AnimatedDiv className="panel-wrapper">
              <AnimatedImage
                src={serviceImg1}
                alt="Nail Extensions"
                style={{ flex: "3 1" }}
              />
              <p>
                {translateString(
                  "Many people struggle with brittle, short, and misshapen nails, but nail extensions offer a solution. In just a few hours, you can enjoy beautiful, uniform, and long nails."
                )}
              </p>
              <button>{translateString("EXTENSIONS")}</button>
            </AnimatedDiv>
          </div>
          <div className={`panel`} ref={(el) => (panelRefs.current[0] = el)}>
            <AnimatedDiv className="panel-wrapper">
              <AnimatedImage
                src={serviceImg2}
                alt="Classic manicure"
                style={{ flex: "3 1" }}
              />
              <p>
                {translateString(
                  "The classic type of manicure is always elegance and modesty. It suits any look and remains popular even today."
                )}
              </p>
              <button>{translateString("CLASSIC")}</button>
            </AnimatedDiv>
          </div>
          <div className="panel" ref={(el) => (panelRefs.current[2] = el)}>
            <AnimatedDiv className="panel-wrapper">
              <AnimatedImage
                src={serviceImg3}
                alt="Nail Art"
                style={{ flex: "3 1" }}
              />
              <p>
                {translateString(
                  "The nail industry is always in sync with global trends, continuously evolving with new designs. If you're tired of basic flowers and swirls, come to me and let's create something truly innovative and inspiring."
                )}
              </p>
              <button>{translateString("DESIGN")}</button>
            </AnimatedDiv>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Services;
